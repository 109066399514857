<template>
  <div
    v-if="updates"
    :class="['InvoiceUpdates', {
      'InvoiceUpdates--mobile': mobile.isMobile,
      'InvoiceUpdates--stacked': stack || mobile.isMobile,
    }]"
    data-cy="invoice-updates"
  >
    <label class="fs-14 fw-med">{{ label }}</label>

    <transition-group
      v-if="updates.length"
      name="Transition__fade"
      tag="div"
    >
      <div
        v-for="(update, index) in displayedInvoiceUpdates"
        :key="`update-${index}`"
        :class="[{
          'row mt-14': !stack && !mobile.isMobile,
          'column mt-18': stack || mobile.isMobile
        }]"
        :data-cy="`invoice-updates-update-${index}`"
      >
        <!-- DATE -->
        <time
          class="InvoiceUpdates__entry-time-label fc-mid fs-14"
          :datetime="update.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
        >
          {{ update.created_at | datetime('MMM D, YYYY') }}
        </time>


        <!-- icon + udpate update_status + author of update + update note -->
        <div :class="['column', { 'ml-12': !stack && !mobile.isMobile }]">
          <div class="row row--align-center row--justify-start">
            <i :class="['fa fa-oval fc-middle', { 'fc-red': flagIcon(index) }]" />

            <!-- invoice update entries w/o notes -->
            <label
              v-if="update.update_status"
              class="InvoiceUpdates__entry-top-label"
            >
              <span
                :class="['fs-14 fw-med capitalize', { 'fc-red': flagIcon(index) }]"
                data-cy="invoice-updates-update-type-label"
              >
                <!--
                  If an invoice was just created or approved from a buyout item, lead the
                  update status with 'Buyout '
                -->
                {{
                  (
                    update.update_status === 'invoice created'
                    && update.notes.toLowerCase().includes('buyout')
                  )
                    ? 'Buyout '
                    : null
                }}
                {{
                  (
                    update.update_status === 'invoice approved'
                    && update.notes.toLowerCase().includes('buyout invoice approved')
                  )
                    ? 'Buyout '
                    : null
                }}
                <!--
                  The text displayed for a "client paid" and "declined" update_type is
                  than we'd like, so we're doing this to display something specific for declined
                  invoice updates
                -->
                {{
                  update.update_status !== 'client paid'
                    ? update.update_type === 'declined' && update.update_status !== 'declined other'
                      ? 'invoice declined'
                      : updateStatusesDisplayNames[update.update_status] || update.update_status
                    // handle the old invoice updates that won't have the correct metadata
                    : update.metadata.update_status
                      || updateStatusesDisplayNames[update.update_status]
                      || update.update_status
                }}
              </span>
              <span class="fs-14 fw-med"> - </span>
              <span class="fs-14 fw-med">
                <time
                  class="fs-14 fw-med"
                  :datetime="update.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
                >
                  {{ update.created_at | datetime('h:mm a') }}
                  <span v-if="update.user_name || update.updated_by_user">
                    by
                  </span>
                </time>
                {{
                  update.updated_by_user && update.updated_by_user.client
                    ? update.updated_by_user.client.shortened_name
                    : update.user_name
                }}
              </span>
            </label>

            <!-- Display when updates are client underwriting updates and not invoice updates -->
            <!-- Client underwriting updates don't have statuses, but should be on this line -->
            <label
              v-else
              class="InvoiceUpdates__entry-top-label fs-14"
            >
              ({{ update.subject }}) {{ update.note }}
            </label>
          </div>

          <!-- NOTE -->
          <div
            v-if="update.notes || update.talked_to"
            class="InvoiceUpdates__entry-container-bottom column"
          >
            <label
              v-if="update.update_status === 'missing paperwork'"
              class="fc-mid fs-14 fw-med"
            >
              <!-- .missing is required when making a "missing paperwork" update -->
              <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1837 -->
              Missing: {{ update.missing || 'N/A' }}
            </label>
            <!-- Does not apply to a complete, non, short, or over payment -->
            <label
              v-if="['payment', 'paid to client'].includes(update.update_status)"
              class="fc-mid fs-14 fw-med"
            >
              <!-- check # is missing from payment invoice updates (not post payments) -->
              <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1858 -->
              {{
                update.check_number_or_ach === 'ach'
                  ? 'ACH'
                  : `Check #: ${update.check_number_or_ach || 'No record'}`
              }}
            </label>
            <!-- Does not apply to a complete, non, short, or over payment -->
            <label
              v-if="update.update_status === 'payment'"
              class="fc-mid fs-14 fw-med"
            >
              <!-- payment date is missing from payment invoice updates (not post payments) -->
              <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1921 -->

              <!-- TODO: fix this ass fuckery backend -->
              <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1921 -->
              Payment Date: {{ update['issue date'] | datetime('hh:mm a on YYYY-MM-DD') }}
              <span
                v-if="!update['issue date']"
                class="fc-mid fs-14 fw-med"
              >
                No record
              </span>
            </label>
            <label
              class="fc-light fs-14"
              data-cy="invoice-updates-notes"
            >
              {{ update.notes }}
              <template v-if="update.duplicate_links && update.duplicate_links.length">
                (
                <span
                  v-for="(duplicate_link, duplicateIndex) in update.duplicate_links"
                  :key="duplicateIndex"
                  class="fs-14 mb-12 mr-0"
                  tabindex="0"
                >
                  {{ duplicate_link.load_number }}
                  <span
                    v-if="duplicate_link.comma"
                    class="fc-light fs-14 ml-n3"
                  >
                    ,
                  </span>
                </span>
                ).
                <router-link
                  class="fc-blue fs-14 mb-12 clickable mr-0"
                  :to="viewUniversalSearch(update.duplicate_links)"
                >
                  View invoices.
                </router-link>
              </template>
              {{
                update.talked_to
                  ? `(Talked to ${update.talked_to} via ${update.talked_to_contact_method})`
                  : null
              }}
            </label>
          </div>
        </div>
      </div>
    </transition-group>

    <div
      v-else
      class="fc-light fs-12 uppercase mt-10"
    >
      {{ emptyLabel }}
    </div>

    <!-- click.stop prevents base-pending-item from collapsing on click -->
    <button
      v-if="Math.max((this.updates.length - this.defaultEntries), 0) > 0"
      @click.stop="toggleViewAllEntries"
      class="InvoiceUpdates__more-button fc-light fs-12 uppercase mt-10"
      href="#"
    >
      {{ displayedShowHideLabel }}
    </button>
  </div>
</template>

<script>
// Helpers
import FlagDisplayNames from '../shared/FlagDisplayNames.json'

export default {
  name: 'InvoiceUpdates',

  props: {
    defaultEntries: {
      type: Number,
      default: 1,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: 'Invoice Updates',
    },
    stack: { // Whether the update elements are stacked in a column
      type: Boolean,
      required: false,
      default: false,
    },
    updates: {
      type: Array,
      required: true,
    },
  },

  created () {
    if (this.$route.name === 'client-profile') this.emptyLabel = 'No Underwriting Updates'
  },

  data () {
    return {
      emptyLabel: 'No invoice updates',
      updateStatusesDisplayNames: FlagDisplayNames,
      viewAllEntries: false
    }
  },

  computed: {
    displayedInvoiceUpdates () {
      return this.viewAllEntries
        ? this.updates
        : this.updates.slice(0, this.defaultEntries)
    },

    displayedShowHideLabel () {
      const hideShow = this.viewAllEntries ? 'Hide' : 'Show'
      const number = Math.max((this.updates.length - this.defaultEntries), 0)
      const entry = number > 1 ? 'entries' : 'entry'
      return `${hideShow} ${number} ${entry}`
    },

    mobile () {
      return this.$store.getters.mobile
    },
  },

  methods: {
    flagIcon (index) {
      // Check for when rendering client underwriting updates
      if (!this.displayedInvoiceUpdates[index].update_status) return 'fa fa-oval fc-light'
      // Here and down: invoice updates
      const updateStatus = this.displayedInvoiceUpdates[index].update_status.toLowerCase()
      // Red actions - all else will be gray
      return [
        'advance taken',
        'approved other',
        'client balance',
        'credit rating',
        'debtor claim',
        'debtor credit weak',
        'debtor not found',
        'declined existing claim',
        'declined low credit rating',
        'declined other',
        'declined possible claim',
        'did not pick up',
        'duplicate invoice',
        'filed on bond',
        'invoice declined',
        'invoice returned to approved from paid',
        'invoice returned to pending from approved',
        'load not delivered',
        'log entry',
        'missing bol',
        'missing paperwork',
        'missing rate con',
        'missing signature',
        'no delivery options',
        'originals required',
        'other issue',
        'paid other',
        'paid to client',
        'paperwork unclear',
        'payment issued',
        'pending low credit rating',
        'pending other',
        'pending existing claim',
        'pending possible claim',
        'possible duplicate',
        'rate con/bol mismatch',
        'rate incorrect',
        'rate mismatch',
        'shortpay reason',
        'unreadable',
        'verification failure',
        'waiting on verification',
        'wrong debtor'
      ].includes(updateStatus)
    },

    viewUniversalSearch (ids) {
      // Avoid potential NavigationDuplicated
      if (this.$route.query.ids && this.$route.query.ids === ids) return null
      const route = {
        name: 'search-results',
        // Query allows us to route to the same route without a NavigationDuplicated error
        // since we're passing a different query, but it means we have to watch for changes
        // in the query in the search-results component(s)
        query: {
          display_id: ids[0] && !isNaN(ids[0].invoice_id)
            ? ids.map(id => id.invoice_id)
            : null,
          ids: ids[0] && isNaN(ids[0].invoice_id)
            ? ids.map(id => id.invoice_id)
            : null
        },
      }
      return route
    },

    toggleViewAllEntries () {
      this.viewAllEntries = !this.viewAllEntries
    },
  }
}
</script>

<style lang="sass" scoped>
.InvoiceUpdates
  $block: &
  padding: rem(24px) rem(28px)
  width: 100%

  .fa-oval
    // For some reason, Chrome/Safari will not render rem(6px) the same size as other browsers
    font-size: rem(6px)

  &__entry-container-bottom
    padding-left: rem(18px) // width of icon in top + space between icon and entry text

  &__entry-time-label
    +flex($align: center, $justify: flex-start, $shrink: 0)
    width: rem(90px)

  &__more-button
    // width of date/time + margin between date/time and icon + padding-left of update description
    margin-left: rem(120px)
    padding: rem(10px) 0

  &__updates-container
    +flex($align: flex-start)
    margin-top: rem(14px)

  &--mobile
    background-color: inherit
    padding: rem(14px) 1rem

    #{$block}__entry-time-label
      font-size: rem(12px)
      line-height: rem(18px)

    #{$block}__more-button
      color: $branding
      margin-left: 0
      margin-top: rem(5px)
      width: 100%

  &--stacked
    .fa-oval
      display: none

    #{$block}__entry-container-bottom
      padding-left: 0
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.updates
    ? _c(
        "div",
        {
          class: [
            "InvoiceUpdates",
            {
              "InvoiceUpdates--mobile": _vm.mobile.isMobile,
              "InvoiceUpdates--stacked": _vm.stack || _vm.mobile.isMobile
            }
          ],
          attrs: { "data-cy": "invoice-updates" }
        },
        [
          _c("label", { staticClass: "fs-14 fw-med" }, [
            _vm._v(_vm._s(_vm.label))
          ]),
          _vm.updates.length
            ? _c(
                "transition-group",
                { attrs: { name: "Transition__fade", tag: "div" } },
                _vm._l(_vm.displayedInvoiceUpdates, function(update, index) {
                  return _c(
                    "div",
                    {
                      key: "update-" + index,
                      class: [
                        {
                          "row mt-14": !_vm.stack && !_vm.mobile.isMobile,
                          "column mt-18": _vm.stack || _vm.mobile.isMobile
                        }
                      ],
                      attrs: { "data-cy": "invoice-updates-update-" + index }
                    },
                    [
                      _c(
                        "time",
                        {
                          staticClass:
                            "InvoiceUpdates__entry-time-label fc-mid fs-14",
                          attrs: {
                            datetime: _vm._f("datetime")(
                              update.created_at,
                              "YYYY-MM-DD hh:mm:ssZ"
                            )
                          }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("datetime")(
                                  update.created_at,
                                  "MMM D, YYYY"
                                )
                              ) +
                              "\n      "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          class: [
                            "column",
                            { "ml-12": !_vm.stack && !_vm.mobile.isMobile }
                          ]
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row row--align-center row--justify-start"
                            },
                            [
                              _c("i", {
                                class: [
                                  "fa fa-oval fc-middle",
                                  { "fc-red": _vm.flagIcon(index) }
                                ]
                              }),
                              update.update_status
                                ? _c(
                                    "label",
                                    {
                                      staticClass:
                                        "InvoiceUpdates__entry-top-label"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            "fs-14 fw-med capitalize",
                                            { "fc-red": _vm.flagIcon(index) }
                                          ],
                                          attrs: {
                                            "data-cy":
                                              "invoice-updates-update-type-label"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                update.update_status ===
                                                  "invoice created" &&
                                                  update.notes
                                                    .toLowerCase()
                                                    .includes("buyout")
                                                  ? "Buyout "
                                                  : null
                                              ) +
                                              "\n              " +
                                              _vm._s(
                                                update.update_status ===
                                                  "invoice approved" &&
                                                  update.notes
                                                    .toLowerCase()
                                                    .includes(
                                                      "buyout invoice approved"
                                                    )
                                                  ? "Buyout "
                                                  : null
                                              ) +
                                              "\n              "
                                          ),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                update.update_status !==
                                                  "client paid"
                                                  ? update.update_type ===
                                                      "declined" &&
                                                    update.update_status !==
                                                      "declined other"
                                                    ? "invoice declined"
                                                    : _vm
                                                        .updateStatusesDisplayNames[
                                                        update.update_status
                                                      ] || update.update_status
                                                  : // handle the old invoice updates that won't have the correct metadata
                                                    update.metadata
                                                      .update_status ||
                                                      _vm
                                                        .updateStatusesDisplayNames[
                                                        update.update_status
                                                      ] ||
                                                      update.update_status
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "fs-14 fw-med" },
                                        [_vm._v(" - ")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "fs-14 fw-med" },
                                        [
                                          _c(
                                            "time",
                                            {
                                              staticClass: "fs-14 fw-med",
                                              attrs: {
                                                datetime: _vm._f("datetime")(
                                                  update.created_at,
                                                  "YYYY-MM-DD hh:mm:ssZ"
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("datetime")(
                                                      update.created_at,
                                                      "h:mm a"
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                              update.user_name ||
                                              update.updated_by_user
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                  by\n                "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                update.updated_by_user &&
                                                  update.updated_by_user.client
                                                  ? update.updated_by_user
                                                      .client.shortened_name
                                                  : update.user_name
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _c(
                                    "label",
                                    {
                                      staticClass:
                                        "InvoiceUpdates__entry-top-label fs-14"
                                    },
                                    [
                                      _vm._v(
                                        "\n            (" +
                                          _vm._s(update.subject) +
                                          ") " +
                                          _vm._s(update.note) +
                                          "\n          "
                                      )
                                    ]
                                  )
                            ]
                          ),
                          update.notes || update.talked_to
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "InvoiceUpdates__entry-container-bottom column"
                                },
                                [
                                  update.update_status === "missing paperwork"
                                    ? _c(
                                        "label",
                                        { staticClass: "fc-mid fs-14 fw-med" },
                                        [
                                          _vm._v(
                                            "\n            Missing: " +
                                              _vm._s(update.missing || "N/A") +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  ["payment", "paid to client"].includes(
                                    update.update_status
                                  )
                                    ? _c(
                                        "label",
                                        { staticClass: "fc-mid fs-14 fw-med" },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                update.check_number_or_ach ===
                                                  "ach"
                                                  ? "ACH"
                                                  : "Check #: " +
                                                      (update.check_number_or_ach ||
                                                        "No record")
                                              ) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  update.update_status === "payment"
                                    ? _c(
                                        "label",
                                        { staticClass: "fc-mid fs-14 fw-med" },
                                        [
                                          _vm._v(
                                            "\n            Payment Date: " +
                                              _vm._s(
                                                _vm._f("datetime")(
                                                  update["issue date"],
                                                  "hh:mm a on YYYY-MM-DD"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                          !update["issue date"]
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "fc-mid fs-14 fw-med"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              No record\n            "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "fc-light fs-14",
                                      attrs: {
                                        "data-cy": "invoice-updates-notes"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(update.notes) +
                                          "\n            "
                                      ),
                                      update.duplicate_links &&
                                      update.duplicate_links.length
                                        ? [
                                            _vm._v(
                                              "\n              (\n              "
                                            ),
                                            _vm._l(
                                              update.duplicate_links,
                                              function(
                                                duplicate_link,
                                                duplicateIndex
                                              ) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: duplicateIndex,
                                                    staticClass:
                                                      "fs-14 mb-12 mr-0",
                                                    attrs: { tabindex: "0" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          duplicate_link.load_number
                                                        ) +
                                                        "\n                "
                                                    ),
                                                    duplicate_link.comma
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fc-light fs-14 ml-n3"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                  ,\n                "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm._v(
                                              "\n              ).\n              "
                                            ),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "fc-blue fs-14 mb-12 clickable mr-0",
                                                attrs: {
                                                  to: _vm.viewUniversalSearch(
                                                    update.duplicate_links
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                View invoices.\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            update.talked_to
                                              ? "(Talked to " +
                                                  update.talked_to +
                                                  " via " +
                                                  update.talked_to_contact_method +
                                                  ")"
                                              : null
                                          ) +
                                          "\n          "
                                      )
                                    ],
                                    2
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            : _c("div", { staticClass: "fc-light fs-12 uppercase mt-10" }, [
                _vm._v("\n    " + _vm._s(_vm.emptyLabel) + "\n  ")
              ]),
          Math.max(this.updates.length - this.defaultEntries, 0) > 0
            ? _c(
                "button",
                {
                  staticClass:
                    "InvoiceUpdates__more-button fc-light fs-12 uppercase mt-10",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.toggleViewAllEntries($event)
                    }
                  }
                },
                [_vm._v("\n    " + _vm._s(_vm.displayedShowHideLabel) + "\n  ")]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }